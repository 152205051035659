import { type Company, type Image as ImageModel } from "@prisma/client";
import React from "react";
import { Image } from "~/components/ui/core/Image";
import { makePublicAssetUrl } from "~/lib/files/makePublicAssetUrl";

type Props = {
  company: Pick<Company, "name"> & {
    logo: ImageModel | null;
  };
};

export const CompanyLabel: React.FC<Props> = ({ company }) => {
  return (
    <div className="flex items-center space-x-2">
      {company.logo && (
        <Image
          alt="company logo"
          className="rounded"
          height={(16 * company.logo.height) / company.logo.width}
          src={makePublicAssetUrl(company.logo.path)}
          width={16}
        />
      )}
      <span>{company.name}</span>
    </div>
  );
};
