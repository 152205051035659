import { Stack, Typography } from "@mui/material";
import { type Company, type Image } from "@prisma/client";
import classNames from "classnames";
import React from "react";
import { CompanyLabel } from "~/components/ui/CompanyLabel";
import { SelectInput } from "~/components/ui/core/BetterSelectInput";
import { type ApiRoute } from "~/hooks/useApi";
import { useListCompaniesQuery } from "~/hooks/useQueries";
import { compact } from "~/lib/lodash";

export type CompanyRowForSelector = Pick<Company, "id" | "name"> & {
  logo: Image | null;
};

type Event = {
  target: {
    name?: string;
    value: CompanyRowForSelector[];
  };
};

const API_PATHS: Record<string, ApiRoute["pathname"]> = {
  vc: "/api/list-portfolio-companies",
  admin: "/api/admin/list-companies",
} as const;

type ApiKey = keyof typeof API_PATHS;

type Props = {
  api: ApiKey;
  name?: string;
  multiple?: boolean;
  placeholder?: string;
  searchPlaceholder?: string;
  value: CompanyRowForSelector[];
  excludedValues?: Pick<Company, "id">[];
  disabled?: boolean;
  label?: JSX.Element;
  onChange: (event: Event) => void;
  onOpen?: () => void;
  counts?: {
    [key: number]: number;
  };
  allowSearchById?: boolean;
  includeVentureCapitals?: boolean;
};

export const CompanySelector: React.FC<Props> = ({
  api,
  name,
  value,
  label,
  excludedValues = [],
  multiple = false,
  disabled = false,
  onOpen,
  onChange,
  placeholder = "Filter companies",
  searchPlaceholder = "Search companies",
  allowSearchById = false,
  includeVentureCapitals = false,
}) => {
  const { data: companiesData } = useListCompaniesQuery({
    path: API_PATHS[api],
    includeVentureCapitals,
  });

  const groups = [
    {
      label: "",
      options: (companiesData?.companies ?? []).filter((company) => {
        const isExcluded = !!excludedValues.find((item) => {
          return item.id === company.id;
        });

        return !isExcluded;
      }),
    },
  ];

  return (
    <SelectInput
      dense
      hideGroupLabels
      disabled={disabled}
      formatLabel={(companies) => {
        if (label) {
          return label;
        }

        const textLabel = companies
          .map((company) => {
            return company.name;
          })
          .join(", ");

        return (
          <div
            className={classNames({
              "relative flex w-full overflow-hidden whitespace-nowrap rounded border border-gray-300 bg-white py-1 px-2 text-left text-sm outline-none focus-within:ring hover:border-primary-400":
                true,
              "text-gray-400": !textLabel,
            })}
          >
            {textLabel || placeholder}
          </div>
        );
      }}
      formatOption={(company) => {
        if (allowSearchById) {
          return (
            <Stack direction="row" justifyContent="center" alignItems="center" gap={2}>
              <CompanyLabel company={company} />
              <Typography variant="caption" color="gray.500">
                #{company.id}
              </Typography>
            </Stack>
          );
        }

        return <CompanyLabel company={company} />;
      }}
      groups={groups}
      multiple={multiple}
      name={name}
      optionToId={(company) => {
        return company.id;
      }}
      placeholder={multiple ? "Companies" : "Company"}
      searchOptions={{
        optionToSearchableStrings: (company) => {
          return compact([allowSearchById ? company.id.toString() : null, company.name]);
        },
        placeholder: searchPlaceholder,
      }}
      value={value}
      width="xl"
      onChange={(event) => {
        onChange(event);
      }}
      onOpen={() => {
        onOpen?.();
      }}
    />
  );
};
