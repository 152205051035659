import classNames from "classnames";
import { useRouter } from "next/router";
import React from "react";
import { Link, type LinkProps } from "~/components/ui/core/Link";
import { isObject } from "~/lib/lodash";

type Props = LinkProps & {
  activeClassName: string;
  inactiveClassName?: string;
  iconActiveClassName?: string;
  icon: React.ReactElement;
  title: string;
  titleActiveClassName?: string;
};

export const ActiveLink: React.FC<Props> = ({
  activeClassName,
  inactiveClassName = "",
  className,
  icon,
  iconActiveClassName = "",
  title,
  titleActiveClassName = "",
  ...props
}) => {
  const router = useRouter();
  const pathname = isObject(props.to) ? props.to.pathname : props.to;
  const isActive = router.route.startsWith(pathname);

  return (
    <Link
      noLinkStyle
      {...props}
      className={classNames(className, {
        [activeClassName]: isActive,
        [inactiveClassName]: !isActive,
      })}
    >
      <div className="flex flex-col items-center space-y-1">
        {React.cloneElement(icon, {
          className: classNames(icon.props.className, {
            [iconActiveClassName]: isActive,
          }),
        })}
        <span className={classNames("text-xs", { [titleActiveClassName]: isActive })}>{title}</span>
      </div>
    </Link>
  );
};
