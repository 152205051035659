import HttpStatus from "http-status-codes";
import { type NextApiHandler } from "next";
import { object, string } from "yup";
import { broadcastTabSyncingEvent } from "~/hooks/useTabSyncing";
import { api } from "~/lib/api";
import { handleExceptionalRightsChange } from "~/lib/exceptionalRightsChange";
import { createMutation } from "~/lib/reactQuery";
import { type YupOutputType } from "~/lib/utils";
import { requestTemporarySuperAdminRights } from "~/services/user/requestTemporarySuperAdminRights";

export const RequestTemporarySuperAdminRightsSchema = object({
  reason: string().required(),
}).noUnknown();

export type RequestTemporarySuperAdminRightsInput = YupOutputType<typeof RequestTemporarySuperAdminRightsSchema>;

const handler: NextApiHandler = async (req, res) => {
  const input = RequestTemporarySuperAdminRightsSchema.validateSync(req.body, { abortEarly: false });

  await requestTemporarySuperAdminRights(req, input);

  return res.status(HttpStatus.OK).json({});
};

export default api(handler, {
  method: "POST",
  authentication: {
    canRequestSuperAdminRights: true,
  },
});

export const useRequestTemporarySuperAdminRightsMutation = createMutation<
  typeof handler,
  typeof RequestTemporarySuperAdminRightsSchema
>({
  path: "/api/user/request-temporary-super-admin-rights",
  schema: RequestTemporarySuperAdminRightsSchema,
  options: () => ({
    onSuccess: () => {
      broadcastTabSyncingEvent({
        event: "super-admin",
        payload: { isSuperAdmin: true },
      });

      handleExceptionalRightsChange();
    },
  }),
});
