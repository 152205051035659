import HttpStatus from "http-status-codes";
import { type NextApiHandler } from "next";
import { number, object, string } from "yup";
import { broadcastTabSyncingEvent } from "~/hooks/useTabSyncing";
import { api } from "~/lib/api";
import { handleExceptionalRightsChange } from "~/lib/exceptionalRightsChange";
import { createMutation } from "~/lib/reactQuery";
import { impersonateCompany } from "~/lib/session";
import { type YupOutputType } from "~/lib/utils";
import { requestExceptionalImpersonation } from "~/services/user/requestExceptionalImpersonation";

export const RequestExceptionalImpersonationSchema = object({
  reason: string().required(),
  companyId: number().required(),
}).noUnknown();

export type RequestExceptionalImpersonationInput = YupOutputType<typeof RequestExceptionalImpersonationSchema>;

const handler: NextApiHandler = async (req, res) => {
  const input = RequestExceptionalImpersonationSchema.validateSync(req.body, { abortEarly: false });

  await requestExceptionalImpersonation(req, input);
  await impersonateCompany(req, { companyId: input.companyId });

  return res.status(HttpStatus.OK).json({});
};

export default api(handler, {
  method: "POST",
  authentication: {},
});

export const useRequestExceptionalImpersonationMutation = createMutation<
  typeof handler,
  typeof RequestExceptionalImpersonationSchema
>({
  path: "/api/user/request-exceptional-impersonation",
  schema: RequestExceptionalImpersonationSchema,
  options: () => ({
    onSuccess: (_, variables) => {
      broadcastTabSyncingEvent({
        event: "company-impersonation",
        payload: { companyId: variables.companyId },
      });

      handleExceptionalRightsChange();
    },
  }),
});
